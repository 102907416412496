import React from 'react'
import PrivacyPolicy from '../../../../components/Legal/Organisation/PrivacyPolicy'

const Page = ({ location }) => {
    return (
        <PrivacyPolicy
            language="is"
            location={location}
        />
    )
}

export default Page
